<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + comment.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique+ comment.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2">
      <div class="flex items-start space-x-4 p-1">
        <router-link :to="{name: 'UserDetail', params: {id: comment.user.id}}" v-if="comment.user"
                     :title="comment.user.username">
          <img
              :src="comment.user && comment.user.avatar ? comment.user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
              :alt="comment.user.username" class="w-12 h-12 rounded-full object-cover">
        </router-link>
        <div class="flex-1 relative">
          <h3 class="font-semibold mb-1 flex">
            <router-link
                :to="{name: 'SongDetail', hash: '#comment-' + comment.id, params: {id: comment.object.id, slug: comment.object.slug, prefix: getPrefixFromSongType(comment.object['__typename'])}}"
                v-if="comment.object && isSongable(comment.object['__typename'])" :title="comment.object.title">
              {{ comment.object.title }}
            </router-link>
            <router-link
                :to="{name: 'PeopleDetail', hash: '#comment-' + comment.id, params: {slug: comment.object.slug, prefix: getRoutePrefixPeople(comment.object['__typename'])}}"
                v-if="comment.object && isPeople(comment.object['__typename'])" :title="comment.object.title">
              {{ comment.object.title }}
            </router-link>
            <router-link
                :to="{name: 'DocumentDetail', hash: '#comment-' + comment.id, params: {id: comment.object.id, slug: comment.object.slug}}"
                v-if="comment.object && comment.object['__typename'] === 'Document'" :title="comment.object.title">
              {{ comment.object.title }}
            </router-link>
            <router-link
                :to="{name: 'DiscussionDetail', hash: '#comment-' + comment.id, params: {id: comment.object.id, slug: comment.object.slug}}"
                v-if="comment.object && comment.object['__typename'] === 'Discussion'" :title="comment.object.title">
              {{ comment.object.title }}
            </router-link>
            <router-link
                :to="{name: 'PlaylistDetail', hash: '#comment-' + comment.id, params: {id: comment.object.id, slug: comment.object.slug}}"
                v-if="comment.object && comment.object['__typename'] === 'Playlist'" :title="comment.object.title">
              {{ comment.object.title }}
            </router-link>
            <router-link
                :to="{name: 'SheetDetail', hash: '#comment-' + comment.id, params: {id: comment.object.id, slug: comment.object.slug}}"
                v-if="comment.object && comment.object['__typename'] === 'Sheet'" :title="comment.object.title">
              {{ comment.object.title }}
            </router-link>
            <router-link :to="{name: 'PageDetail', hash: '#comment-' + comment.id, params: {slug: comment.object.slug}}"
                         v-if="comment.object && comment.object['__typename'] === 'Page'" :title="comment.object.title">
              {{ comment.object.title }}
            </router-link>
            <router-link :to="{name: 'RoleDetail', hash: '#comment-' + comment.id, params: {slug: comment.object.slug}}"
                         v-if="comment.object && comment.object['__typename'] === 'Role'" :title="comment.object.name">
              {{ comment.object.name }}
            </router-link>
            <router-link :to="{name: 'UploadDetail', hash: '#comment-' + comment.id, params: {id: comment.object.id}}"
                         v-if="comment.object && comment.object['__typename'] === 'Upload'" :title="'Bản upload ' + comment.object.id">
              {{ comment.object.name }}
            </router-link>
            <span v-if="sticky" class="mr-1"><i class="fas fa-thumbtack"></i></span>
          </h3>
          <p class="text-sm text-gray-400 mb-2 space-x-2">
            <router-link class="text-dot no-dot text-xs" :to="{name: 'UserDetail', params: {id: comment.user.id}}"
                         v-if="comment.user" :title="comment.user.username">
              {{ comment.user.username }}
            </router-link>
            <span class="text-dot text-xs">{{ timeago(comment.created_at) }}</span>
          </p>
          <p v-html="comment.content"></p>
        </div>
      </div>
    </td>
    <td class="p-2 space-x-2">
      <template v-if="comment.deleted_at">
        <span v-if="comment.deleter" class="whitespace-nowrap inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full bg-red-500 text-white">
          {{ comment.deleter.username }}
        </span>
      </template>
      <template v-else>
        <span v-if="comment.status === 2"
              class="px-2 py-1 text-xs rounded-full bg-red-500 text-white whitespace-nowrap">Chờ
          kiểm duyệt</span>
        <span v-if="comment.request_status === 'done'"
              class="px-2 py-1 text-xs rounded-full bg-green-500 text-white whitespace-nowrap">Đã xử lý</span>
        <span v-if="comment.request_status === 'rejected'"
              class="px-2 py-1 text-xs rounded-full bg-red-500 text-white whitespace-nowrap">Từ chối</span>
        <span v-if="!comment.request_status && comment.is_request === 1"
              class="px-2 py-1 text-xs rounded-full bg-red-500 text-white whitespace-nowrap">Chưa xử lý</span>
      </template>
    </td>
  </tr>
</template>

<script>
import {
  getPrefixFromSongType,
  getRoutePrefixPeople,
  isPeople, isSongable,
  stripContent,
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminCommentItem",
  props: {
    comment: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    stripContent(content, length) {
      return stripContent(content, length);
    },
    timeago(x) {
      return timeago(x);
    },
    getPrefixFromSongType(type) {
      type = type.toLowerCase();
      return getPrefixFromSongType(type);
    },
    isSongable(type) {
      return isSongable(type);
    },
    getRoutePrefixPeople(type) {
      type = type.toLowerCase();
      return getRoutePrefixPeople(type);
    },
    isPeople(type) {
      return isPeople(type);
    }
  },
  watch: {
    isChecked: {
      handler(newVal) {
        this.$emit("checkedComment", this.comment, newVal);
      }
    },
  }
}
</script>
